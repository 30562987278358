.title {
  font-size: 30px;
  font-weight: 700;
  margin-bottom: 10px;
}

.description {
  font-size: 14px;
  font-weight: 400;

}