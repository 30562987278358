body.desktop {
  background-color: #f8f8f8 !important;
}

.b2b-light-border {
  border: 1px solid var(--b2b-light-border-color) !important;
}

.b2b-medium-border {
  border: 1px solid var(--b2b-medium-border-color) !important;
}

.b2b-top-light-border {
  border-top: 1px solid var(--b2b-light-border-color) !important;
}

.b2b-bottom-light-border {
  border-bottom: 1px solid var(--b2b-light-border-color) !important;
}

.ltr {
  direction: ltr;
  text-align: left;
}

.ltr .b2b-right-light-border {
  border-right: 1px solid var(--b2b-light-border-color) !important;
}

.rtl .b2b-right-light-border {
  border-left: 1px solid var(--b2b-light-border-color) !important;
}

.ltr .b2b-left-light-border {
  border-left: 1px solid var(--b2b-light-border-color) !important;
}

.rtl .b2b-left-light-border {
  border-right: 1px solid var(--b2b-light-border-color) !important;
}

.b2b-coming-soon {
  color: var(--theme);
  text-align: center;
}

.b2b__breadcrumb__wrapper {
  background-color: #fff;
  border: 1px solid var(--b2b-light-border-color);
  border-radius: var(--main-border-radius);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.desktop .b2b__breadcrumb__wrapper {
  padding: 1rem !important;
}

#b2b-hotel-see-all-properties * {
  transition: 0.2s all;
}

#b2b-hotel-see-all-properties {
  width: fit-content;
  padding-bottom: 2px;
  color: var(--theme-orange-darker);
}

#b2b-hotel-see-all-properties:hover * {
  color: var(--theme-orange) !important;
}

.b2b-avatar-uploader-icon-wrapper {
  position: absolute;
  bottom: -10px;
  left: -15px;
}

.rtl .b2b-avatar-uploader-icon-wrapper {
  left: auto;
  right: -15px;
}

.b2b-pre-invoice-sticky-wrapper {
  position: sticky;
  top: 16px;
}

.b2b-get-user-error {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--theme-secondary);
}

.b2b-currency-switcher-btn {
  border-color: #fff !important;
  color: #fff !important;
}

.b2b-auth-layout {
  min-height: 95dvh;
  position: relative;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 80px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.b2b-auth-layout > div {
  max-width: 100%;
  width: var(--desktop-max-width);
  z-index: 5;
}

.b2b-auth-layout::before,
.b2b-auth-layout::after {
  content: '';
  position: absolute;
}

.b2b-auth-layout::before {
  background-color: var(--theme-transparent);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.b2b-auth-layout::after {
  background-image: url(@hotelian/assets/images/search__form__bg-dark.svg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  left: 100px;
  right: 100px;
  top: 100px;
  bottom: 100px;
  z-index: 2;
}

.b2b-auth-layout-topbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  height: 80px;
  z-index: 100;
  background-color: var(--theme) !important;
}

.b2b-auth-layout-topbar > div {
  max-width: 100%;
  width: var(--desktop-max-width);
  margin: auto;
  display: flex;
  justify-content: space-between;
  height: 100%;
  align-items: center;
}

.b2b-auth-layout-submit-button {
  height: 50px !important;
}

.b2b-auth-layout-footer {
  min-height: 5dvh;
  position: relative;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: #113386;
  border-top: 1px solid #0f2d76;
  color: white;
  width: 100%;
}

.b2b-auth-layout-footer > div {
  max-width: 100%;
  width: var(--desktop-max-width);
  margin: auto;
}

.b2b-auth-layout-description-grid-item {
  position: relative;
  padding-right: 3rem;
}

.rtl .b2b-auth-layout-description-grid-item {
  padding-right: 0;
  padding-left: 3rem;
}

.b2b-auth-layout-form-grid-item {
  position: relative;
}

.rtl .b2b-auth-layout-form-grid-item {
  padding-left: 0;
  padding-right: 3rem;
}

.b2b-auth-layout-description-grid-item::before {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  height: 250px;
  width: 1px;
  background-color: #fff;
}

.rtl .b2b-auth-layout-description-grid-item::before {
  right: auto;
  left: 0;
}

.desktop .b2b-app-layout .b2b-content-container {
  padding-top: 0;
}

.desktop .b2b-general-layout .b2b-content-container {
  padding-top: 0;
}

.b2b-captcha-ctn {
  border-radius: var(--main-border-radius);
  background-color: #e7e7e7;
  height: 56px;
  width: 150px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.b2b-captcha-ctn button {
  margin-right: 10px;
}

.rtl .b2b-captcha-ctn button {
  margin-left: 10px;
  margin-right: 0;
}

.b2b-app-layout .b2b-footer,
.b2b-general-layout .b2b-footer {
  background-color: var(--foreground-gray-color);
  padding-top: 30px;
  margin-top: 2rem;
}

.b2b-footer-copy-right {
  background-color: var(--foreground-gray-color-darker) !important;
  padding-top: 30px;
  padding-bottom: 30px;
}

.desktop .b2b-app-layout .b2b-header-banner {
  /* min-height: 40px; */
  position: relative;
  z-index: 1;
  background-image: url(../public/images/app-header-bg.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.desktop .b2b-app-layout .b2b-header-banner > div {
  z-index: 5;
  position: relative;
  padding-top: 0;
}

.desktop .b2b-app-layout .b2b-header-banner::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-transparent-x);
  opacity: 0.8;
  z-index: 2;
}

.desktop .b2b-app-layout .b2b-app-header {
  z-index: 120;
}

.desktop .b2b-general-layout .b2b-general-layout-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 120;
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(../public/images/app-header-bg.jpg);
  height: 90px;
}

.desktop .b2b-general-layout .content-container {
  padding-top: 90px;
}

.desktop .b2b-general-layout .b2b-general-layout-header > div {
  position: relative;
  height: 100%;
}

.desktop .b2b-general-layout .b2b-general-layout-header > div::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--theme-transparent-x);
}

.desktop .b2b-general-layout .b2b-general-layout-login-button {
  color: #fff;
  background-color: #2a56c4;
}

.desktop .b2b-app-layout .b2b-app-header > div {
  background-color: var(--theme);
  height: 80px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  /* padding: 15px 20px 5px 20px; */
  padding: 20px;
}

.b2b-desktop-nav .b2b-desktop-nav-item-link {
  color: #fff;
  padding: 10px 12px 0 12px;
  position: relative;
  outline: none;
}

.b2b-desktop-nav .b2b-desktop-nav-item-link::after {
  content: '';
  position: absolute;
  bottom: -10px;
  height: 1px;
  left: 50%;
  right: 50%;
  background-color: #fff;
  margin: auto;
  transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
}

.b2b-desktop-nav .b2b-desktop-nav-item-link:hover::after {
  left: 0;
  right: 0;
}

.b2b-desktop-nav .b2b-desktop-nav-item-link.b2b-ignore-desktop-link::after {
  display: none;
}

.b2b-main-alert {
  padding: 10px;
  position: relative;
}

.b2b-main-alert a {
  text-decoration: underline !important;
  color: var(--theme-secondary);
}

.b2b-main-alert a:hover {
  color: var(--theme);
}

.b2b-main-alert > div {
  margin: 0;
}

.b2b-main-alert::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -1;
  background-color: #f5e8e0;
  border-radius: var(--main-border-radius);
}

.desktop .b2b-main-page-search-box-wrapper {
  background-color: var(--foreground-gray-color);
  border-radius: var(--main-border-radius);
  margin-top: -45px;
  padding: 10px 10px;
  position: relative;
  z-index: 11;
}

.desktop .b2b-hotel-tabs-item {
  transition: background-color 0.15s;
  -o-transition: background-color 0.15s;
  -moz-transition: background-color 0.15s;
  -webkit-transition: background-color 0.15s;
}

.b2b-app-layout-user-popover {
  position: relative;
  padding-top: 15px;
  padding-bottom: 15px;
  width: 320px;
}

.b2b-app-layout-user-popover::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 6px;
  background-color: var(--theme-secondary);
}

.b2b-desktop-filters-ctn .filter-searcher-ctn,
.desktop .b2b-desktop-filters-ctn .b2b-search-filters-item {
  background-color: #fff;
  border: 1px solid var(--b2b-light-border-color);
  border-radius: var(--main-border-radius);
}

.b2b-desktop-filters-ctn .custom-search-component-form,
.b2b-mobile-search-name-ctn .custom-search-component-form {
  background-color: #fff !important;
}

.b2b-desktop-filters-ctn .filter-searcher-item {
  padding-right: 12px !important;
}

.rtl .b2b-desktop-filters-ctn .filter-searcher-item {
  padding-left: 12px !important;
  padding-right: 0 !important;
}

.b2b-search-main-loading-ctn {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 50;
  /* background-color: #e7e7e7aa; */
}

.b2b-search-main-loading-ctn > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 125px;
  padding-bottom: 125px;
}

.b2b-search-main-loading-slogan {
  animation: search-loading-slogan 1s linear infinite;
}

.b2b-auth-layout-hamburger-ctn {
  display: none;
}

.b2b-deal-title-text {
  font-size: 0.8em;
  font-weight: 600;
}

.b2b-pay-at-property-description {
  font-size: 0.8em;
}

.b2b-user-profile-credit {
  font-size: 0.8em;
}

.b2b-user-profile-level {
  position: absolute;
  top: -12px;
  left: -15px;
  width: 30px;
  height: 30px;
}

#b2b-main-page-banner-btn {
  font-size: 1.2rem;
  position: absolute;
  bottom: 50px;
  right: 161px;
  padding-right: 2.2rem;
  padding-left: 2.2rem;
}

.rtl.ar_lang #b2b-main-page-banner-btn {
  font-size: 1.2rem;
  position: absolute;
  bottom: 50px;
  right: 205px;
  padding-right: 2.2rem;
  padding-left: 2.2rem;
}

.rtl.fa_lang #b2b-main-page-banner-btn {
  font-size: 1.2rem;
  position: absolute;
  bottom: 50px;
  right: 163px;
  padding-right: 2.2rem;
  padding-left: 2.2rem;
}

@media (min-width: 1441px) {
  #b2b-main-page-banner-btn {
    font-size: 1.2rem;
    position: absolute;
    bottom: 60px;
    right: 195px;
    padding-right: 2.2rem;
    padding-left: 2.2rem;
  }

  .rtl.ar_lang #b2b-main-page-banner-btn {
    font-size: 1.2rem;
    position: absolute;
    bottom: 50px;
    right: 238px;
    padding-right: 2.2rem;
    padding-left: 2.2rem;
  }

  .rtl.fa_lang #b2b-main-page-banner-btn {
    font-size: 1.2rem;
    position: absolute;
    bottom: 50px;
    right: 205px;
    padding-right: 2.2rem;
    padding-left: 2.2rem;
  }
}

.b2b-occupancy-form-room-title,
.b2b-reserve-confirm-passenger-title {
  margin-top: -27px;
  background-color: #fff;
  width: fit-content;
  padding: 0 10px;
}

.b2b-occupancy-form-room-board-type,
.b2b-reserve-confirm-board-type {
  padding: 0 10px;
}

.b2b-book-loading-cmp {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.75);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: var(--main-border-radius);
  z-index: 10200;
  box-shadow: 0 0 15px rgba(70, 70, 70, 0.3);
  min-width: 300px;
  padding: 20px;
}

.b2b-book-loading-cmp-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10100;
  background-color: rgba(0, 0, 0, 0.3);
}

.b2b-payment-verification-card {
  width: 350px;
  max-width: 100%;
}

@supports not (backdrop-filter: blur(30px)) {
  .b2b-book-loading-cmp {
    background-color: #fff;
  }
}

@keyframes search-loading-slogan {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

@media (max-width: 960px) {
  .b2b-auth-layout::after {
    left: 50px;
    right: 50px;
    top: 0;
    bottom: 0;
  }

  .b2b-auth-layout-description-grid-item,
  .b2b-auth-layout-form-grid-item {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .b2b-auth-layout-form-grid-item {
    padding-top: 3rem;
  }

  .b2b-auth-layout-description-grid-item::before {
    display: none;
  }

  .b2b-auth-layout::after {
    left: 20px;
    right: 20px;
  }

  .b2b-auth-layout-nav-list-ctn {
    display: none;
  }

  .b2b-auth-layout-hamburger-ctn {
    display: block;
  }

  .b2b-auth-layout-logo {
    text-align: center;
  }
}

.datePickerContainer .MuiFilledInput-underline {
  border-radius: 3px !important;
}
.datePickerContainer .MuiFilledInput-underline::after {
  display: none;
}
.datePickerContainer .MuiFilledInput-underline::before {
  border-bottom: unset !important;
}

.datePickerContainer .MuiFilledInput-root {
  background-color: var(--filled-input-background-color);
}

.datePickerContainer .MuiFormLabel-root {
  color: #e68a24 !important;
  /*font-weight:550 !important;*/
  text-transform: capitalize !important;
}
.datePickerContainer .MuiInputAdornment-root button span {
  font-size: 11px;
}

.datePickerContainer .MuiIconButton-root {
  padding: 0;
  position: relative;
  top: 5px;
}
.datePickerContainer .MuiIconButton-root svg {
  font-size: 17px;
}
.datePickerContainer input {
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 7px;
  padding-top: 32px;
  color: var(--dark-gray-color);
}

.grayBg {
  background-color: var(--filled-input-background-color);
}

.filterInput {
  padding: 10px 10px 13px 10px;
  width: 100%;
  border-radius: 3px !important;
}

.filterInput input {
  color: var(--dark-gray-color);
}

.filterSelect {
  width: 100%;
}
.filterSelect .MuiSelect-root {
  height: 42px;
  border-radius: 3px !important;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
}

.filterSelect input {
  color: var(--dark-gray-color);
}

.link {
  color: var(--bootstrap-border-primary);
}

.slowMove {
  transition: 0.3s;
}

.ltr .filterButtons {
  bottom: 85px;
  right: 30px;
}
.rtl .filterButtons {
  bottom: 85px;
  left: 30px;
}

.filterBoxFooter {
  position: absolute;
  background: white;
  bottom: 30px;
  min-height: 45px;
  width: 100%;
}

@media (min-width: 400px) {
  .maximizedFilterBox {
    height: 1200px;
  }

  .minimizedFilterBox {
    height: 520px;
  }

  .filterButtons {
    bottom: 20px !important;
  }

  .invoicePageFilter {
    height: 570px;
  }
}

@media (min-width: 960px) {
  .maximizedFilterBox {
    height: 520px;
  }

  .minimizedFilterBox {
    height: 300px;
  }

  .filterButtons {
    bottom: 20px !important;
  }
  .invoicePageFilter .inputContainer {
    width: 100%;
  }
  .invoicePageFilter {
    height: 200px;
  }
  .transactionFilterBox .minimizedFilterBox {
    height: 190px;
  }
  .transactionFilterBox .maximizedFilterBox {
    height: 290px;
  }
}
@media (min-width: 1366px) {
  .invoicePageFilter {
    height: 160px;
  }
  .invoicePageFilter .inputContainer {
    width: calc(100% - 110px);
  }

  .filterBoxFooter {
    background: transparent;
  }
  .minimizedFilterBox {
    height: 190px;
  }
  .maximizedFilterBox {
    height: 290px;
  }
  .invoicePageFilter .filterButtons {
    bottom: 55px !important;
  }
}

.datePickerContainer .MuiFormHelperText-filled.Mui-error {
  white-space: nowrap;
  font-size: 11px;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: hidden;
}

.datePickerContainer .MuiFilledInput-underline.Mui-error {
  box-shadow: 0 0 0 1px red;
  /*box-sizing: border-box;*/
  /*background-color: rgba(255,0,0,0.2);*/
}

.auth-dialog-container {
  background-image: url(@hotelian/assets/images/dialog-world-map.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
