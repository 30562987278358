.maintenance_overflowX__disable {
  overflow-x: hidden !important;
}

.maintenance_container {
  width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 3rem 0;
}

.maintenance_image {
  width: 40rem;
  max-width: 80vw;
}

.maintenance_title_container {
  margin-top: 1rem;
  color: #01247d;
  font-size: 1.2rem;
  font-weight: bold;
  display: flex;
  align-items: baseline;
}

.maintenance_title {
  margin: 0;
  text-align: center;
}

.maintenance_title_lines {
  margin: 0 0.5rem;
}

.maintenance_title_lines__mirror {
  transform: scale(-1, 1);
}

.maintenance_description {
  margin-top: 0.5rem;
  width: 37rem;
  max-width: 90vw;
  color: #7c7c7c;
  text-align: center;
  font-size: 0.9em;
}

@media only screen and (max-width: 40em) {
  .maintenance_title_lines {
    display: none;
  }

  .maintenance_title_container {
    margin: 0.5rem 0.2rem;
  }

  .maintenance_description {
    margin: 0;
  }
}
