.captcha-ctn {
  border-radius: var(--main-border-radius);
  background-color: #e7e7e7;
  height: 56px;
  width: 100%;
  display: flex;
  padding: 0 15px;
  align-items: center;
  button {
    margin-right: 10px;
  }
  img {
    width: 80px;
    height: auto !important;
  }

  .noCaptchaImage {
    width: 80px;
  }
}
.rtl .captcha-ctn button {
  margin-left: 10px;
  margin-right: 0;
}
