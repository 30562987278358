@import 'bootstrap.min.css';
@import 'bootstrap.rtl.css';
@import 'mobile.css';
@import 'variables.css';
::selection {
  background-color: var(--theme);
  color: #fff;
}

html {
  scroll-behavior: smooth;
}
body {
  line-height: 1;
}
html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--main-font-family), sans-serif !important;
}
.desktop-main-animation {
  animation: desktop-main 0.5s ease-out;
}
@keyframes desktop-main {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
/* ignore scroll-x after login by google */
#g_a11y_announcement {
  display: none;
}
body.desktop {
  min-width: 1170px;
}
a {
  color: inherit;
  text-decoration: none !important;
}
* {
  box-sizing: border-box;
}
.desktop .footer {
  margin-top: 40px;
}
/* Disable number input arrows */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
input[type='file'] {
  color: #6c757d;
}
input[type='file']::file-selector-button {
  border: 1px solid #007bff;
  color: #007bff;
  border-radius: var(--main-border-radius);
  background-color: transparent;
  cursor: pointer;
}
.loading {
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid var(--light-primary-color);
  border-top-color: var(--theme);
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
  left: calc(50% - 15px);
  top: calc(50% - 15px);
  position: fixed;
  z-index: 1;
}
.MuiPagination-ul {
  flex-wrap: nowrap !important;
}
.main-searchbox-container input,
.main-searchbox-container .intellisense-input,
.mobile-intellisense-items-ctn .intellisense-input {
  background-color: var(--foreground-gray-color);
}
.custom-outlined-input {
  background-color: var(--foreground-gray-color);
}
.linear-searchbox-wrapper input,
.linear-searchbox-wrapper .intellisense-input {
  background-color: transparent;
  border: none !important;
}
.linear-searchbox-wrapper .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.linear-searchbox-input-have-indicator {
  position: relative;
}
.linear-searchbox-input-have-indicator::after {
  content: '';
  position: absolute;
  top: 10px;
  bottom: 10px;
  right: 0;
  width: 1px;
  background-color: var(--gray-color);
}
.rtl .linear-searchbox-input-have-indicator::after {
  right: auto;
  left: 0;
}
.linear-searchbox-submit-btn-ctn {
  width: 56px !important;
  padding: 2px;
  border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
}
.linear-searchbox-first-input {
  border-radius: var(--main-border-radius) 0 0 var(--main-border-radius);
}
.rtl .linear-searchbox-submit-btn-ctn {
  border-radius: var(--main-border-radius) 0 0 var(--main-border-radius);
}
.rtl .linear-searchbox-first-input {
  border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
}
.linear-searchbox-submit-btn-ctn button {
  height: 52px;
}
.boldable-searchbox {
  z-index: 20;
  transition: all 0.2s ease-out;
}
.bold-searchbox {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
}
.hover-transparent:hover {
  background-color: transparent !important;
}
.hover-theme:hover {
  color: var(--theme) !important;
}
.text-transform-lowercase {
  text-transform: lowercase;
}
.search-hotel-item {
  border: 1px solid rgba(0, 0, 0, 0.12);
}
.search-hotel-item img {
  background-color: rgba(0, 0, 0, 0.12);
  color: transparent;
}
.search-hotel-item .search-hotel-item-book-summary {
  border-left: 1px solid rgba(0, 0, 0, 0.12);
}
.search-box-destination-type {
  color: #b3b3b3 !important;
  text-transform: uppercase;
  display: inline-block;
}

.search-box-destination-subtitle {
  display: inline-block;
}

.rtl .search-hotel-item .search-hotel-item-book-summary {
  border-left: none;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}
.search-hotel-item .search-hotel-item-acc-summary {
  padding: 5px 10px;
  border-radius: var(--main-border-radius);
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.12);
  width: 100%;
}
.google-map-popup-ctn img {
  height: 160px;
  border-radius: var(--main-border-radius);
  background-color: rgba(0, 0, 0, 0.12);
}
.image-backdrop {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: transparent;
}
.search-hotel-item .search-hotel-item-tax {
  padding: 5px 10px;
  border-radius: var(--main-border-radius);
  text-align: center;
  border: 1px solid var(--theme-secondary);
  color: var(--theme-secondary);
}
.desktop .main-searchbox-container .MuiInputLabel-shrink {
  /* display: none !important; */
  background-color: var(--foreground-gray-color);
  margin: auto -10px;
  padding: 2px 10px;
  border-radius: var(--main-border-radius) var(--main-border-radius) 0 0;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
}
.text-direction,
.text-direction * {
  text-align: left !important;
}
.rtl .text-direction,
.rtl .text-direction * {
  text-align: right !important;
}
.bg--transparent {
  background-color: transparent !important;
}
.bg--secondary {
  background-color: #d3daeb !important;
}
.bg--light-primary:not(.MuiButton-containedPrimary) {
  background-color: var(--light-primary-color) !important;
}
.bg-foreground-gray {
  background-color: var(--foreground-gray-color) !important;
}
.bg-foreground-gray-darker {
  background-color: var(--foreground-gray-color-darker) !important;
}
.linear-searchbox-wrapper {
  background-color: var(--foreground-gray-color);
  border-radius: var(--main-border-radius);
}
.border--light-primary:not(.MuiButton-containedPrimary) {
  border: 1px solid var(--light-primary-color);
}
.border-bottom-light-primary {
  border-bottom: 1px solid var(--light-primary-color);
}
button:focus,
input:focus,
select:focus {
  outline: none;
}
.bg-theme-secondary {
  background-color: var(--theme-secondary) !important;
}
.bg-black {
  background-color: #000 !important;
}
.bg-teal {
  background-color: var(--teal-color);
}
.bg-theme {
  background-color: var(--theme) !important;
}
.bg-theme-transparent-x {
  background-color: var(--theme-transparent-xx);
}
.bg-theme-light {
  background-color: var(--light-primary-color) !important;
}
.color-secondary,
.color-secondary * {
  color: var(--theme-secondary) !important;
}
.color-theme {
  color: var(--theme) !important;
}
.border-theme {
  border: 1px solid var(--theme) !important;
}
.color-teal {
  color: var(--teal-color) !important;
}
.color-green {
  color: var(--theme-green) !important;
}
.color-orange {
  color: var(--theme-orange) !important;
}
.bg-theme-light-xx {
  background-color: var(--theme-light-xx) !important;
}
.bg-gray {
  background-color: var(--gray-color);
}
.gray-color {
  color: var(--gray-color);
}
.bg-green {
  background-color: var(--theme-green);
}
.content-container {
  min-height: 93vh;
}
.main__wrapper {
  margin-top: 70px;
  margin-bottom: 100px;
}
.content__wrapper {
  max-width: var(--desktop-max-width);
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.border-bottom-dashed-gray {
  border-bottom: 3px dashed var(--foreground-gray-color);
}
.shadow--sm-xx {
  -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 8%);
}
.shadow--sm-x {
  -webkit-box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 12%);
}
.border-none {
  border: none;
}
.Mui-input-border {
  border-color: #bbbbbb !important;
}
.ssr-spinner-ctn {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  z-index: 2005;
  transition: all 200ms linear;
  -o-transition: all 200ms linear;
  -moz-transition: all 200ms linear;
  -webkit-transition: all 200ms linear;
}
@keyframes ssr-loading-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}
.header {
  z-index: 1010;
}
.desktop .header {
  padding-top: 8px;
}
.desktop .gray-topbar {
  background-color: var(--foreground-gray-color) !important;
  padding-bottom: 8px;
}
.code-validator-timer-cmp {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 10px;
  padding-left: 10px;
}
.rtl .code-validator-timer-cmp {
  left: 0;
  right: auto;
}
.sk-cube-grid {
  width: 40px;
  height: 40px;
  margin: 100px auto;
}
/* images spinner styles */
.spinner {
  width: 40px;
  height: 40px;

  position: relative;
  margin: 100px auto;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #aaa;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
/* main spinner styles */
.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: var(--theme-secondary);
  float: left;
  -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
  animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
}
.sk-cube-grid .sk-cube1 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube2 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube3 {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}
.sk-cube-grid .sk-cube4 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube5 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.sk-cube-grid .sk-cube6 {
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-cube-grid .sk-cube7 {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
}
.sk-cube-grid .sk-cube8 {
  -webkit-animation-delay: 0.1s;
  animation-delay: 0.1s;
}
.sk-cube-grid .sk-cube9 {
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1);
  }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1);
  }
}

.hotelian-logo {
  width: 200px;
}

.hotelian-logo-footer {
  width: 200px;
}

.list-style-none {
  list-style: none;
}
.date-picker .MuiButton-root {
  min-width: unset !important;
}
.MuiButton-root,
.MuiTab-root {
  text-transform: initial !important;
}
.MuiButton-root {
  min-width: auto !important;
}
.MuiTab-root {
  min-width: 0 !important;
}
.MuiAutocomplete-root {
  width: auto !important;
}
.MuiPopover-paper > div {
  max-width: 540px;
}
.topbar-nav a {
  padding: 20px;
  display: flex;
  flex-direction: column;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  height: 100%;
  line-height: 18.2px;
  font-weight: 700;
  /* color: #d9d9d9; */
  position: relative;
  -ms-flex-direction: column;
  -ms-flex-align: center;
}

/* .topbar-nav a:hover,
.topbar-nav a.active-navigation-link {
  color: rgba(255, 255, 255, 1);
} */

.topbar-nav a:hover::after,
.topbar-nav a.active-navigation-link::after {
  content: '';
  width: 50%;
  border-bottom: 3px solid var(--theme-secondary);
  position: absolute;
  bottom: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}
.content__wrapper--tb {
  max-width: var(--mobile-max-width);
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.ft__links .ft__title {
  text-transform: capitalize;
  color: #00247d;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 15px;
}
.ft__links .ft__list li {
  text-transform: capitalize;
  padding: 0 0 20px 0;
}
.ft__links .ft__list li a {
  font-size: 14px;
  color: var(--gray-color);
  /* text-decoration: underline !important; */
}
.ft__links .ft__list li a:hover {
  color: var(--theme);
  text-decoration: underline !important;
}
.ft__lpp {
  width: 100%;
  min-height: 45px;
  background: var(--theme);
}
.ft__lpp .cp-right {
  color: #fff;
}
.icon-ratio--lg {
  width: auto;
  height: 20px;
}
.currency-switcher-btn {
  border-color: var(--theme) !important;
}
.flags-images {
  height: 26px;
  width: 26px;
  object-fit: cover;
  -o-object-fit: cover;
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: var(--main-border-radius);
}
.flags-images.changer {
  height: 20px;
  width: 20px;
}
.full-screen-center {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}
.border-bottom-dashed {
  border-bottom: 1px dashed rgb(222, 225, 234);
}
.sub__reg-form {
  font-size: 16px;
  line-height: 22.4px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.sub__reg-form div {
  position: relative;
}
.sub__reg-form div:before {
  content: '';
  position: absolute;
  top: 0;
  left: 100%;
  width: 0;
  height: 0;
  border-top: calc(40px / 2) solid #ffffff;
  border-right: calc(40px / 3) solid transparent;
  border-bottom: calc(40px / 2) solid #ffffff;
}
.sub__reg-form.sub__reg-form-secondary div:before {
  border-top: calc(40px / 2) solid var(--light-primary-color);
  border-right: calc(40px / 3) solid transparent;
  border-bottom: calc(40px / 2) solid var(--light-primary-color);
}
.sub__reg-form div input {
  background-color: #ffffff;
  font-size: inherit;
  width: 240px;
  height: 40px;
  padding: 4px 12px;
  border-radius: 2px 0 0 2px;
}
.sub__reg-form.sub__reg-form-secondary div input {
  background-color: var(--light-primary-color);
}
.sub__reg-form button {
  margin-left: 18px;
  position: relative;
  height: 40px;
  padding: 4px 8px;
  color: var(--theme-secondary);
  background-color: #ffffff;
  font-weight: 700;
  border-radius: 0 2px 2px 0;
  border: none;
}
.sub__reg-form.sub__reg-form-secondary button {
  color: #ffffff;
  background-color: var(--theme-secondary);
}
.sub__reg-form button:before {
  content: '';
  position: absolute;
  top: 0;
  right: 100%;
  width: 0;
  height: 0;
  border-top: calc(40px / 2) solid transparent;
  border-right: calc(40px / 3) solid #ffffff;
  border-bottom: calc(40px / 2) solid transparent;
}
.sub__reg-form.sub__reg-form-secondary button:before {
  border-top: calc(40px / 2) solid transparent;
  border-right: calc(40px / 3) solid var(--theme-secondary);
  border-bottom: calc(40px / 2) solid transparent;
}
.rtl .sub__reg-form div:before {
  left: auto;
  right: 100%;
  border-left: calc(40px / 3) solid transparent;
  border-right: none;
}
.rtl .sub__reg-form div input {
  border-radius: 0 2px 2px 0;
}
.rtl .sub__reg-form button {
  margin-right: 18px;
  margin-left: auto;
  border-radius: 2px 0 0 2px;
}
.rtl .sub__reg-form button:before {
  right: auto;
  left: 100%;
  border-right: none;
  border-left: calc(40px / 3) solid #ffffff;
}
.rtl .sub__reg-form.sub__reg-form-secondary button:before {
  border-left: calc(40px / 3) solid var(--theme-secondary);
}
.rtl .styles_react-code-input__CRulA {
  direction: ltr !important;
}
.styles_react-code-input__CRulA {
  display: flex;
  flex-wrap: nowrap;
}
.styles_react-code-input-container__tpiKG {
  text-align: center;
  margin: auto !important;
  width: auto !important;
}
.styles_react-code-input-container__tpiKG input:focus {
  border-color: var(--theme) !important;
}
.styles_react-code-input-container__tpiKG input {
  caret-color: var(--theme) !important;
}
/* .styles_react-code-input-container__tpiKG input {
  caret-color: var(--theme) !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  border-bottom: 3px solid #ccc !important;
  border-radius: 0 !important;
  width: 40px !important;
  margin: 0 5px;
}*/

.validation-error-on-top {
  position: absolute;
  bottom: 100%;
  right: 0;
  color: var(--theme-secondary);
}
.rtl .validation-error-on-top {
  right: auto;
  left: 0;
}
.validation-error-normal {
  color: var(--theme-secondary);
}
.validation-error {
  border-radius: 0.1rem;
  padding: 0.5rem 1rem;
  font-size: 0.76rem;
  /*color: #3a3a3a;*/
  color: var(--theme-secondary);
  background: #fff;
  border: 1px solid var(--theme-secondary);
  text-align: center;
  width: unset !important;
  position: absolute;
  z-index: 4;
  margin-top: -0.5rem;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%);
}
.validation-error::before,
.validation-error::after {
  position: absolute;
  left: -2.5px;
  margin-left: 50%;
  width: 10px;
  height: 5px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
.validation-error::before {
  content: '';
  top: -5px;
  border-bottom: 5px solid var(--theme-secondary);
}
.validation-error::after {
  content: '';
  top: -4px;
  border-bottom: 5px solid #fff;
}
.bkd__card__check {
  padding: 1px 6px;
  background-color: #909bb9;
  color: #ffffff;
  font-size: 12px;
  line-height: 14px;
}
.wr__links-dark,
.wr__links-dark:visited {
  color: #606f98;
  padding: 0.25rem 0;
  border-bottom: 1px solid #909bb9;
}
.text--sm-x {
  font-size: 70% !important;
}
.MuiFormControl-root {
  width: 100%;
}
.phone-number-input .phone-number-prefix-ctn {
  flex: 1 !important;
}
.phone-number-input .phone-number-number-input-ctn {
  flex: 5 !important;
}
.text--gray--dark-x {
  color: #8f8f8f !important;
}
@media (min-width: 992px) {
  .prf__asd__container {
    width: 240px;
  }
}
.footer-social-media-icon {
  width: 18px;
}
.breadcrumb__wrapper {
  width: 100%;
  /* background-color: #f2f6fd; */
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
}
.breadcrumb__wrapper .breadcrumb__cnt {
  /*margin-right: auto;*/
  /*margin-left: auto;*/
  overflow-x: auto;
  overflow-y: hidden;
}
.breadcrumb__wrapper .breadcrumb__cnt nav {
  color: rgba(0, 0, 0, 0.54);
  font-size: 1rem;
  font-family: var(--main-font-family), sans-serif !important;
  font-weight: 400;
  margin: 0;
  line-height: 1.5;
}
.breadcrumb__wrapper .breadcrumb__cnt nav ol {
  margin: 0;
  display: flex;
  padding: 0;
  flex-wrap: nowrap;
  list-style: none;
  align-items: center;
}
.breadcrumb__wrapper .breadcrumb__cnt nav ol li.breadcrumb-separator {
  display: flex;
  margin-left: 8px;
  user-select: none;
  margin-right: 8px;
}
.breadcrumb__wrapper .breadcrumb__cnt nav ol li {
  white-space: nowrap !important;
}
.text--muted--dark-x {
  color: #606f98 !important;
}
.text--muted--dark {
  color: #7784a9 !important;
}
.profile-personal-info-form-ctn {
  margin-left: 130px;
}
.rtl .profile-personal-info-form-ctn {
  margin-right: 130px;
  margin-left: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.cursor-auto {
  cursor: auto !important;
}
.change-avatar-icon-ctn {
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(41, 41, 41, 0.4);
}
.text--muted--base {
  color: #909bb9 !important;
}
.text--light {
  font-weight: 300 !important;
}
.em-vf__cnt.fp-pg {
  border-top: 15px solid var(--light-primary-color);
}
.em-vf__cnt {
  min-width: 300px;
  max-width: 500px;
  width: 35%;
}
.em-vf__env.fp-pg {
  background-color: var(--light-primary-color);
}
.em-vf__env {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: -57.5px;
  background-color: #e4f5e3;
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.em-vf__env.fp-pg img {
  margin-left: 10px;
  width: 50px;
}
.em-vf__content {
  padding: 40px 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-items: center;
}
.searchbox__container {
  position: relative;
  background-image: url(./images/search__form__bg.png);
  background-position: center center;
  /* background-repeat: repeat-x; */
  background-repeat: no-repeat;
  background-size: contain;
}
.page__banner--extend {
  height: 600px;
}
.searchbox__container .sb__content__wrapper {
  width: 100%;
  padding-top: 70px;
  margin: auto;
}
.searchbox__container .sb__clouds__container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.searchbox__container .sb__clouds__container .cld--d--left--md--sng {
  height: 15px;
  top: 45%;
  right: 7%;
}
.searchbox__container .sb__clouds__container .cld--d--right--sm--sng {
  height: 10px;
  top: 52%;
  right: 32%;
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
}
.searchbox__container .sb__clouds__container .cld--d--right--sm--dbl {
  height: 24px;
  top: 53%;
  left: 8%;
}
.dyn__text {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  overflow: hidden;
  display: inline-block;
  user-select: none;
}
.dyn__text ul {
  transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
}
.intellisense-input {
  z-index: 10;
}
.intellisense-items-ctn .MuiButton-label {
  display: block;
}
.intellisense-items-ctn {
  position: absolute;
  /* top: 50%; */
  top: calc(100% + 4px);
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 5;
  border: 1px solid #bbb;
  box-shadow: 0 0 20px rgba(50, 50, 50, 0.2);
  animation: intellisense-ctn 0.1s ease-out;
  -o-animation: intellisense-ctn 0.1s ease-out;
  -moz-animation: intellisense-ctn 0.1s ease-out;
  -webkit-animation: intellisense-ctn 0.1s ease-out;
  max-height: 350px;
  overflow: auto;
}

.intellisense-items-container-additional-width {
  width: 150%;
}

@keyframes intellisense-ctn {
  from {
    opacity: 0.5;
    transform: scale(0.8) translateY(-10px);
  }
  to {
    opacity: 1;
    transform: scale(1) translateY(0);
  }
}
.room-selector {
  border: 1px solid var(--foreground-gray-color) !important;
}
.room-selector:hover,
.room-selector-open {
  border-color: var(--theme) !important;
}
.rooms-count-action-btn {
  width: 30px !important;
  color: var(--theme-green) !important;
  height: 30px;
  border-color: var(--gray-color) !important;
  border-radius: var(--main-border-radius) !important;
  min-width: auto !important;
}
.rooms-count-number {
  width: 20px;
}
.date-picker-top-days-item {
  width: 14.2%;
}
.date-picker-top-days-ctn {
  padding: 5px 0;
  background-color: var(--gray-color-light-x);
}
.mui-popover-max-width-free .MuiPopover-paper > div {
  max-width: unset;
}
.disable-input-focus-ctn:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--theme);
}
.disable-input-focus {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 11;
  cursor: pointer;
}
.arrow-in-date-selection {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--theme-secondary);
  width: 32px;
  height: 32px;
  border-radius: 16px;
  z-index: 9;
  color: #fff;
}
.arrow-in-date-selection > div {
  transform: translateY(1px);
}
.ltr .checkout-date input {
  padding-left: 20px !important;
}
.rtl .checkout-date input {
  padding-right: 20px !important;
}
.ltr .checkout-date label {
  padding-left: 5px !important;
}
.rtl .checkout-date label {
  padding-right: 5px !important;
}
.ltr .checkout-date label.MuiInputLabel-shrink {
  padding-left: 22px !important;
}
.rtl .checkout-date label.MuiInputLabel-shrink {
  padding-right: 22px !important;
}
.hotel-stars {
  color: #ff8500;
}
.hotel-item-view-on-map {
  color: #ff8500 !important;
}
.disabled-star * {
  color: #aaa !important;
}
.disabled-on-hotel-star {
  color: #d4daea !important;
}
.disabled-star::after {
  content: '';
  position: absolute;
  top: 50%;
  height: 1px;
  left: -2px;
  right: -2px;
  background-color: #000;
}
.rd__banner__wrapper {
  width: 100%;
  padding: 0;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}
.bg-gray-light {
  background-color: var(--gray-color-light) !important;
}
.color-gray {
  color: var(--gray-color) !important;
}
.primary-slider-navigation .swiper-button-prev,
.primary-slider-navigation .swiper-button-next {
  color: var(--theme) !important;
}
.gray-slider-navigation .swiper-button-prev,
.gray-slider-navigation .swiper-button-next {
  width: 32px;
  height: 32px;
  background-color: var(--foreground-gray-color);
  border-radius: var(--main-border-radius);
  color: #444;
}
.gray-slider-navigation .swiper-button-prev::after,
.gray-slider-navigation .swiper-button-next::after {
  font-size: 12px;
}
.gray-slider-navigation .swiper-button-prev {
  transform: translateY(-6px) translateX(-10px);
}
.gray-slider-navigation .swiper-button-next {
  transform: translateY(-6px) translateX(10px);
}
.rtl .gray-slider-navigation .swiper-button-prev {
  transform: translateY(-6px) translateX(10px);
}
.rtl .gray-slider-navigation .swiper-button-next {
  transform: translateY(-6px) translateX(-10px);
}
.rd__bnr__dtl {
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
  -moz-transition: all 0.4s ease-out;
  -o-transition: all 0.4s ease-out;
}
.rd__bnr__dtl__small {
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-box-shadow: 0 2px 3px 0 rgb(0, 0, 0, 0.16);
  box-shadow: 0 2px 3px 0 rgb(0, 0, 0, 0.16);
}
.pswp__button--share {
  display: none;
}
.hotel-slider .swiper-button-prev,
.hotel-slider .swiper-button-next {
  color: #444;
  background-color: var(--light-primary-color);
  width: 44px;
  height: 44px;
  border-radius: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.text-header {
  color: var(--theme) !important;
  position: relative;
  padding-left: 10px;
}
.rtl .text-header {
  color: var(--theme) !important;
  position: relative;
  padding-right: 10px;
}
.text-header::before {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
  border-top: 5px solid transparent;
  border-left: 7px solid var(--theme);
  border-bottom: 5px solid transparent;
}
.rtl .text-header::before {
  left: auto;
  right: 0;
  border-right: 7px solid var(--theme);
  border-left: none;
}
.hotel-description-paragraph {
  line-height: 220% !important;
}
.hotel-description-paragraph a {
  color: var(--theme-secondary);
}
.hotel-description-paragraph a:hover {
  text-decoration: underline !important;
}
.image-lightbox-ctn {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 1);
  z-index: 2000;
  animation: image-lightbox-ctn-animation 0.2s ease-out;
}
.image-lightbox-ctn .image-lightbox-close-ctn {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 2001;
}
.image-lightbox-ctn .image-lightbox-close-ctn button {
  background-color: #ffffff44;
  color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.08);
  transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
}
.desktop .image-lightbox-ctn .image-lightbox-close-ctn button:hover {
  background-color: #ffffff;
  color: #000;
}
@keyframes image-lightbox-ctn-animation {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.facilities-checklist {
  clear: both;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 40px;
  -moz-column-gap: 40px;
  column-gap: 40px;
}
.desktop .facilities-checklist .facilities-checklist-item {
  margin-bottom: 16px;
}
.hotel-header-buttons {
  width: 95px;
  height: 77px;
  border: none;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.08);
  -webkit-box-shadow: 0 2px 4px 0 rgb(0, 0, 0, 0.08);
  transition: height, width 0.4s ease-out;
  -o-transition: height, width 0.4s ease-out;
  -moz-transition: height, width 0.4s ease-out;
  -webkit-transition: height, width 0.4s ease-out;
}
.hotel-header-buttons-collapsed {
  width: 65px;
  height: 45px;
}
.hotel-header-buttons:hover {
  background-color: #f2f2f2;
}
.hotel-header-buttons img {
  width: 40px;
  height: auto;
}
.not-found-image {
  width: 70px;
  height: auto;
}
.hotel-availibility-footer {
  border-top: 1px dashed #cecece;
}
.hotel-availibility-policy,
.hotel-availibility-price {
  border-left: 1px dashed #cecece;
}
.rtl .hotel-availibility-policy,
.rtl .hotel-availibility-price {
  border-left: none;
  border-right: 1px dashed #cecece;
}
.policies-marker {
  /* background-color: #eeb300; */
  border-radius: var(--main-border-radius);
}
.absolute-over {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
}
.lh--lg-x {
  line-height: 190%;
}
.lh--lg-xx {
  line-height: 220%;
}
.rd__room__card {
  box-shadow: 0 1.5px 3px 0 rgb(0, 0, 0, 0.2);
}
/* .search-main-content {
  margin: 45px auto;
} */
.sort-ctn .MuiOutlinedInput-inputMarginDense {
  padding-top: 8.5px !important;
  padding-bottom: 9px !important;
}
.sort-ctn .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.sort-ctn .MuiSelect-select:focus {
  background-color: transparent !important;
}
.sort-ctn .sort-ctn-btn {
  position: relative;
}
.sort-ctn .sort-ctn-btn::before {
  content: '';
  background-color: var(--light-primary-color);
  position: absolute;
  bottom: 0;
  left: 50%;
  right: 50%;
  height: 3px;
  transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
}
.sort-ctn .sort-ctn-btn-active::before {
  left: 0;
  right: 0;
}
.absolute-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.make-ltr {
  direction: ltr !important;
  text-align: left !important;
}
.direction-ltr {
  direction: ltr;
}

.gateway-option {
  box-sizing: border-box !important;
  border: 1px solid var(--foreground-gray-color-x) !important;
  height: 80px;
}

.gateway-option .gateway-description-image {
  width: 100%;
}

.gateway-description-image-container {
  width: 180px;
  height: 100%;
}

.gateway-option .gateway-main-image {
  width: 80px;
  aspect-ratio: 16/9;
}

.gateway-option .gateway-main-image-container {
  width: 75px;
}

@media (max-width: 420px) {
  .gateway-option .gateway-main-image-container {
    width: 60px;
  }

  .gateway-description-image-container {
    width: 150px;
    height: 100%;
  }
}

@media (max-width: 370px) {
  .gateway-option .gateway-main-image-container {
    width: 55px;
  }

  .gateway-description-image-container {
    width: 140px;
    height: 100%;
  }
}

.gateway-option input[type='radio'] {
  accent-color: var(--theme);
}

.gateway-option.gateway-option-active {
  border: 2px solid var(--theme) !important;
}

.gateway-option .gateway-radio-container {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  right: 10px;
}

.gateway-option-text {
  text-align: left;
}

.rtl .gateway-option-text {
  text-align: right;
}

.rtl .gateway-option .gateway-radio-container {
  left: 20px;
}

.gateway-option input[type='radio'] {
  cursor: pointer;
  accent-color: var(--theme);
}

.gateway-option > span {
  height: 100%;
}

.gateways-image-wrapper {
  display: flex;
  height: 100%;
}

.gateways-image-wrapper img {
  width: 100%;
}

.gateway-option-active {
  border: 2px solid var(--theme) !important;
}

.reserve-room-details-room-item {
  border-bottom: 1px solid black;
}
.date-picker-days-ctn {
  background-color: #f2f2f2;
}
.date-picker-item {
  height: 37px;
  /* margin: 2px 0; */
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all 0.1s linear;
}
.date-picker-item:not(.date-picker-item.date-picker-item-disabled):active {
  background-color: var(--theme-green-light);
}
.date-picker-item-today:not(.date-picker-item-selected) {
  border: 2px solid var(--theme-green);
  border-radius: var(--main-border-radius);
}
.date-picker-item-selected {
  /* background-color: #ddd; */
  background-color: var(--theme-green-light);
  color: #fff;
}
.date-picker-item-start-selected {
  border-radius: var(--main-border-radius) 0 0 var(--main-border-radius);
  background-color: var(--theme-green);
  color: #fff;
}
.date-picker-item-end-selected {
  border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
  background-color: var(--theme-green);
  color: #fff;
}
.rtl .date-picker-item-end-selected:not(.make-ltr .date-picker-item-end-selected) {
  border-radius: var(--main-border-radius) 0 0 var(--main-border-radius);
}
.rtl .date-picker-item-start-selected:not(.make-ltr .date-picker-item-start-selected) {
  border-radius: 0 var(--main-border-radius) var(--main-border-radius) 0;
}
.date-picker-item-disabled {
  color: rgba(170, 170, 170, 0.5);
}
.google-map-popup-ctn {
  width: 200px;
}
.google-map-popup-ctn-skeleton-container {
  min-height: 330px;
  overflow: hidden !important;
}
.generals-header-image {
  border-bottom-right-radius: 50%;
  overflow: hidden;
}
.rtl .generals-header-image {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 50%;
}
.collapse-body {
  height: auto;
}
.contact-form {
  border-radius: 5px;
  border-top: 4px solid var(--theme);
  -webkit-box-shadow: 2px 2px 10px 2px rgb(0, 0, 0, 0.14);
  box-shadow: 2px 2px 10px 2px rgb(0, 0, 0, 0.14);
  overflow: hidden;
}
.hide-alt-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
.hotel-images-lazy-loading-ctn {
  background-color: #fff;
}
.bnr--rbn__container.bnr--success {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#3e7d37),
    color-stop(20%, #50a047),
    color-stop(80%, #50a047),
    to(#3e7d37)
  );
  background: -o-linear-gradient(left, #3e7d37 0%, #50a047 20%, #50a047 80%, #3e7d37 100%);
  background: linear-gradient(to right, #3e7d37 0%, #50a047 20%, #50a047 80%, #3e7d37 100%);
}
.bnr--rbn__container.bnr--success:before,
.bnr--rbn__container.bnr--success:after {
  border-top: 12px solid #3e7d37;
}
.bnr--rbn__container.bnr--danger {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#d90429),
    color-stop(20%, #ef233c),
    color-stop(80%, #ef233c),
    to(#d90429)
  );
  background: -o-linear-gradient(left, #d90429 0%, #ef233c 20%, #ef233c 80%, #d90429 100%);
  background: linear-gradient(to right, #d90429 0%, #ef233c 20%, #ef233c 80%, #d90429 100%);
}
.bnr--rbn__container.bnr--danger:before,
.bnr--rbn__container.bnr--danger:after {
  border-top: 12px solid #d90429;
}
.bnr--rbn__container.bnr--info {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#0353a4),
    color-stop(20%, #0466c8),
    color-stop(80%, #0466c8),
    to(#0353a4)
  );
  background: -o-linear-gradient(left, #0353a4 0%, #0466c8 20%, #0466c8 80%, #0353a4 100%);
  background: linear-gradient(to right, #0353a4 0%, #0466c8 20%, #0466c8 80%, #0353a4 100%);
}
.bnr--rbn__container.bnr--info:before,
.bnr--rbn__container.bnr--info:after {
  border-top: 12px solid #0353a4;
}
.bnr--rbn__container.bnr--warning {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#ff9500),
    color-stop(20%, #ffb700),
    color-stop(80%, #ffb700),
    to(#ff9500)
  );
  background: -o-linear-gradient(left, #ff9500 0%, #ffb700 20%, #ffb700 80%, #ff9500 100%);
  background: linear-gradient(to right, #ff9500 0%, #ffb700 20%, #ffb700 80%, #ff9500 100%);
}
.bnr--rbn__container.bnr--secondary {
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#7d8597),
    color-stop(20%, #979dac),
    color-stop(80%, #979dac),
    to(#7d8597)
  );
  background: -o-linear-gradient(left, #7d8597 0%, #979dac 20%, #979dac 80%, #7d8597 100%);
  background: linear-gradient(to right, #7d8597 0%, #979dac 20%, #979dac 80%, #7d8597 100%);
}
.bnr--rbn__container.bnr--secondary:before,
.bnr--rbn__container.bnr--secondary:after {
  border-top: 12px solid #7d8597;
}
.bnr--rbn__container {
  position: absolute;
  top: 20px;
  left: -12px;
  right: -12px;
  height: 45px;
  color: #ffffff;
  text-transform: capitalize;
  border-radius: 10px 10px 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-shadow: 0 2.5px 5px 0 rgb(0 0 0 / 20%), 0 2.5px 5px 0 rgb(0 0 0 / 15%);
  box-shadow: 0 2.5px 5px 0 rgb(0 0 0 / 20%), 0 2.5px 5px 0 rgb(0 0 0 / 15%);
}
.bnr--rbn__container:before {
  left: 0;
  border-left: 12px solid transparent;
}
.bnr--rbn__container:before,
.bnr--rbn__container:after {
  content: '';
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
}
.bnr--rbn__container:after {
  right: 0;
  border-right: 12px solid transparent;
}
.gm-iv-address {
  display: none !important;
}
.gm-iv-container {
  display: none !important;
}
.vt__avt {
  width: 30px;
  height: 30px;
  border-radius: var(--main-border-radius);
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.ticket-admin-message {
  background-color: #d4dae9;
}
.ticket-user-message {
  background-color: #80bb016e;
}
.ticket-messages-container {
  max-width: calc(100% - 76px);
}
.text-main-error {
  color: var(--theme-secondary);
  /* color: #ed5565;
  background: -webkit-linear-gradient(
    45deg,
    #ed5565 10%,
    var(--theme-secondary) 70%,
    var(--theme) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
}
.text--gray--dark-xx {
  color: #828282 !important;
}
.top-hotel-cnt {
  width: 360px !important;
}
.main-city-top-hotel-cnt {
  width: 280px !important;
}
.main-top-hotels-summary-ctn {
  line-height: 1.5rem;
}
.main-city-top-hotel-item {
  height: 240px !important;
  border: 1px solid #ddd;
  background-color: #fff;
  border-radius: var(--main-border-radius);
  overflow: hidden;
  position: relative;
  padding: 8px;
}
.main-city-top-hotel-item-non-slider {
  height: auto !important;
}
.main-city-top-hotel-item > img {
  height: 150px;
}
.top-destinations-top-hotel {
  padding: 10px 0;
}
.top-destinations-top-hotel::before,
.top-destinations-top-hotel::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--theme);
  height: 0;
  transition: all 0.2s ease-out;
}
.top-destinations-top-hotel::before {
  top: 0;
}
.top-destinations-top-hotel::after {
  bottom: 0;
}
.active-top-hotel-item::before,
.active-top-hotel-item::after {
  height: 7px;
}
.top-hotel-description {
  background-color: #f2f2f2;
  height: 75px;
  padding: 8px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.top-hotel-image-ctn-1 {
  width: 270px;
  height: 188px;
  background-color: #f2f2f2;
}
.top-hotel-image-ctn-2 {
  width: 60px;
  height: 60px;
  margin-bottom: 4px;
  background-color: #f2f2f2;
}
.top-hotel-small-image-ctn {
  margin-left: 4px;
}
.rtl .top-hotel-small-image-ctn {
  margin-left: 0;
  margin-right: 4px;
}
.top-cities-wrapper {
  position: relative;
  background-color: #ffffff;
  background-position-x: center;
  background-position-y: top;
  background-repeat: no-repeat;
  background-size: 100% auto;
}
.desktop .top-cities-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(15%, rgba(255, 255, 255, 0)),
    color-stop(60%, white),
    to(white)
  );
  background-image: -o-linear-gradient(top, rgba(255, 255, 255, 0) 15%, white 60%, white 100%);
  background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 15%, white 60%, white 100%);
}
.top-cities-big-item {
  height: 360px;
}
.top-cities-item .top-cities-item-absolute-collapse-ctn {
  background-color: var(--theme-transparent-xx);
  color: #fff;
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
}
.top-cities-item .top-cities-item-absolute-collapse-ctn .top-cities-item-collapse-title {
  height: 40px;
}
.top-city-slider-ctn {
  width: 150px !important;
}
.verify-account-notice-ctn {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1390;
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
@supports not (backdrop-filter: blur(30px)) {
  .ssr-spinner-ctn,
  .verify-account-notice-ctn {
    background-color: #fff;
  }
}
.guests-popover-ctn {
  width: 420px;
  left: auto !important;
}
.guests-popover-ctn select {
  padding-top: 4px;
  padding-bottom: 4px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: var(--main-border-radius);
}
.with-radius {
  border-radius: var(--main-border-radius) !important;
}
.with-radius-bottom-left {
  border-end-start-radius: var(--main-border-radius) !important;
}
.with-radius-bottom-right {
  border-end-end-radius: var(--main-border-radius) !important;
}
.with-radius-top-right {
  border-start-end-radius: var(--main-border-radius) !important;
}
.with-radius-top-left {
  border-start-start-radius: var(--main-border-radius) !important;
}
.radius-0 {
  border-radius: 0 !important;
}
.rtl .guests-popover-ctn {
  right: auto !important;
}
.top-hotel-item {
  height: 250px;
}
.top-hotel-item .top-hotel-item-desc {
  position: absolute;
  bottom: 6px;
  left: 6px;
  right: 6px;
  border-radius: var(--main-border-radius);
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
}
.top-hotel-rate {
  position: absolute;
  top: 10px;
  padding: 3px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: var(--main-border-radius);
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  display: flex;
  z-index: 1;
}
.hotel-reviews-rate {
  top: 10px;
  left: 10px;
  transform: translateX(0);
}
.rtl .hotel-reviews-rate {
  right: 10px;
  left: auto;
}
.top-hotel-rate .rate-ctn {
  width: 36px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--main-border-radius);
  background-color: var(--light-primary-color);
  color: #000;
}
.top-hotel-rate .reviews-ctn {
  width: 95px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;
}
.main-magazine-item {
  height: 180px;
}
.top-cities-top-destinations {
  height: 215px;
  border-radius: var(--main-border-radius);
  overflow: hidden;
}
.top-cities-top-destinations img {
  transition: all 1s 0.1s cubic-bezier(0.2, 0.68, 0.38, 0.98);
  -o-transition: all 1s 0.1s cubic-bezier(0.2, 0.68, 0.38, 0.98);
  -moz-transition: all 1s 0.1s cubic-bezier(0.2, 0.68, 0.38, 0.98);
  -webkit-transition: all 1s 0.1s cubic-bezier(0.2, 0.68, 0.38, 0.98);
}
.desktop .top-cities-top-destinations:hover img {
  transform: scale(1.1);
}
.top-cities-top-destinations-content {
  background-color: #e5e5e5;
}
.top-cities-top-destinations-images::before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  z-index: 6;
  background: transparent;
  background-image: linear-gradient(0deg, #03206788 30%, transparent 60%);
  transform: translateY(20%);
  transition: all 1s 0.1s cubic-bezier(0.2, 0.68, 0.38, 0.98);
  -o-transition: all 1s 0.1s cubic-bezier(0.2, 0.68, 0.38, 0.98);
  -moz-transition: all 1s 0.1s cubic-bezier(0.2, 0.68, 0.38, 0.98);
  -webkit-transition: all 1s 0.1s cubic-bezier(0.2, 0.68, 0.38, 0.98);
}
.desktop .top-cities-top-destinations-images:hover::before {
  transform: translateY(0);
}
.top-cities-top-destinations-images .position-absolute {
  text-shadow: 0 0 5px rgba(30, 30, 30, 0.6);
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 7;
}
.guests-popover-desktop-done-btn-ctn {
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  right: 0;
  left: 0;
}
.cluster-class,
.cluster-class * {
  font-weight: bolder !important;
}
.cluster-class > div {
  transform: translateY(-11px);
}
.sort-selection-ctn .MuiInput-underline:before {
  display: none;
}
.sort-selection-ctn * {
  color: var(--theme) !important;
}
.availibility-sorts-and-filters > div {
  border: 1px solid #a8a8a8;
  border-radius: var(--main-border-radius);
}
.availibility-sorts-and-filters .hotel-availibility-sort-ctn .MuiInput-underline:before {
  display: none;
}
.availibility-sorts-and-filters .hotel-availibility-sort-ctn .MuiSelect-root {
  background-color: #fff;
}
.availibility-sorts-and-filters .hotel-availibility-filter-ctn {
  position: relative;
}
.availibility-sorts-and-filters .hotel-availibility-filter-ctn::after {
  content: '';
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 10px;
  width: 1px;
  background-color: #a8a8a8;
}
.availibility-sorts-and-filters .hotel-availibility-filter-ctn:last-child:after {
  display: none;
}
.rtl .availibility-sorts-and-filters .hotel-availibility-filter-ctn::after {
  right: auto;
  left: 0;
}
.custom-popover-ctn {
  position: relative;
}
.custom-content-ctn {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  right: 0;
  top: calc(100% + 10px);
  transform: translate(0, 25px);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  transition: all 0.4s cubic-bezier(0.75, -0.02, 0.2, 0.97);
}
.rtl .custom-content-ctn {
  right: auto;
  left: 0;
}
.custom-content-ctn-left {
  right: auto;
  left: 0;
}
.rtl .custom-content-ctn-left {
  left: auto;
  right: 0;
}
.custom-popover-ctn:hover .custom-content-ctn {
  z-index: 10;
  opacity: 1;
  visibility: visible;
  transform: translate(0, 0);
  background-color: #fff;
  border-radius: var(--main-border-radius);
}
.hotel-images-ctn {
  height: 380px;
}
.hotel-images-ctn .hotel-images-map-ctn {
  height: 65%;
}
.hotel-images-ctn .hotel-images-show-others-ctn {
  height: 35%;
}
.hotel-images-show-others-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: var(--main-border-radius);
}
.hotel-review-rate-cmp-ctn {
  display: flex;
  flex-wrap: nowrap;
}
.hotel-review-rate-cmp-ctn .hotel-review-rate-symbol-ctn {
  margin-right: 5px;
  background-color: rgba(0, 0, 0, 0.12);
  height: 12px;
  border-radius: 6px;
  width: 32px;
  position: relative;
  overflow: hidden;
}
.rtl .hotel-review-rate-cmp-ctn .hotel-review-rate-symbol-ctn {
  margin-right: auto;
  margin-left: 5px;
}
.hotel-review-rate-cmp-ctn .hotel-review-rate-symbol-ctn .hotel-review-rate-symbol-progress-bar-ctn {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  background-color: #008ceb;
}
.rtl .hotel-review-rate-cmp-ctn .hotel-review-rate-symbol-ctn .hotel-review-rate-symbol-progress-bar-ctn {
  left: auto;
  right: 0;
}
.hotel-review-rate-cmp-ctn .hotel-review-rate-symbol-ctn:last-child {
  margin: 0;
}
.hotel-points-item-title {
  width: 140px;
}
.hotel-rooms-item {
  position: relative;
  border-radius: var(--main-border-radius);
  border: 1px solid #a8a8a8;
  overflow: hidden;
  /* padding: 0 7px; */
}
.desktop .hotel-rooms-item .room-item-reservation-summary,
.desktop .hotel-rooms-item .room-item-free-cancellation,
.desktop .hotel-rooms-item .room-item-board-types {
  border-left: 1px solid #a8a8a8;
}
.desktop.rtl .hotel-rooms-item .room-item-reservation-summary,
.desktop.rtl .hotel-rooms-item .room-item-free-cancellation,
.desktop.rtl .hotel-rooms-item .room-item-board-types {
  border-left: none;
  border-right: 1px solid #a8a8a8;
}
/* .hotel-rooms-item::after,
.hotel-rooms-item::before {
  content: "";
  position: absolute;
  width: 7px;
  top: 0;
  bottom: 0;
  background-color: var(--light-primary-color);
}
.hotel-rooms-item-disabled::after,
.hotel-rooms-item-disabled::before {
  background-color: var(--foreground-gray-color);
}
.hotel-rooms-item::after {
  left: 0;
}
.hotel-rooms-item::before {
  right: 0;
} */
#cta {
  width: 100%;
  position: absolute;
  transform: translate(-5px, -10px);
}
#cta .animated-arrow {
  left: 0;
}
.animated-arrow {
  position: absolute;
  margin-left: 0px;
  width: 12px;
  height: 12px;
  background-size: contain;
  top: 0;
}
.segunda {
  margin-top: 8px;
}
@keyframes bounceAlpha {
  0% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
  25% {
    opacity: 0;
    transform: translateY(10px) scale(0.8);
  }
  26% {
    opacity: 0;
    transform: translateY(-10px) scale(0.8);
  }
  55% {
    opacity: 1;
    transform: translateY(0px) scale(1);
  }
}
.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.animated-arrow.primera.bounceAlpha {
  animation-name: bounceAlpha;
  animation-duration: 2s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.animated-arrow {
  animation-name: bounceAlpha;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.animated-arrow.primera {
  animation-name: bounceAlpha;
  animation-duration: 2s;
  animation-delay: 0.2s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.usering-layout-ctn {
  min-height: 93vh;
}
.custom-tab-ctn button {
  width: 150px !important;
}
.mui-stepper-item-clickable {
  cursor: pointer;
  opacity: 1;
  transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
}
.mui-stepper-item-clickable:hover {
  opacity: 0.7;
}
.custom-mui-stepper .MuiStepIcon-root.MuiStepIcon-active,
.custom-mui-stepper .MuiStepIcon-root.MuiStepIcon-completed,
.custom-mui-stepper .MuiStepLabel-label.MuiStepLabel-active,
.custom-mui-stepper .MuiStepLabel-label.MuiStepLabel-completed {
  color: var(--theme-secondary) !important;
}
.contact-us-map-wrapper {
  height: 400px;
}
.about-us-ctn .about-us-paragraph {
  line-height: 30px;
}
.about-us-ctn .about-us-image {
  width: 100%;
  height: 340px;
  object-fit: cover;
  border-radius: var(--main-border-radius);
}
.room-changer-section-title {
  width: 80px;
}
@media (max-width: 400px) {
  .hotel-points-item-title {
    width: 90px;
  }
}
@media (max-width: 380px) {
  .view-on-map-text {
    display: none;
  }
}
.unavailible-over-ctn {
  background-color: rgba(255, 255, 255, 0.55);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  z-index: 100;
}
.phone-number-countries-images-ctn img {
  width: 25px !important;
  height: auto !important;
  object-fit: cover !important;
  -o-object-fit: cover !important;
}
.usering-dialog-close-btn-ctn {
  position: absolute;
  top: 10px;
  right: 10px;
}
.rtl .usering-dialog-close-btn-ctn {
  right: auto;
  left: 10px;
}
.filters-ctn-popup {
  width: 330px;
}
.confirm-reserve-modal-arrow-ctn {
  position: relative;
  display: flex;
  justify-content: center;
}
.confirm-reserve-modal-arrow-ctn::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--foreground-gray-color);
}
.confirm-reserve-modal-arrow-ctn > div {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  border: 1px solid var(--theme-secondary);
  color: var(--theme-secondary);
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}
.dashboard-active-menu-item {
  position: relative;
}
.dashboard-active-menu-item::after {
  content: '';
  position: absolute;
  right: 100%;
  width: 8px;
  top: 10px;
  bottom: 10px;
  border-radius: var(--main-border-radius);
  background-color: var(--theme-secondary);
}
.rtl .dashboard-active-menu-item::after {
  right: auto;
  left: 100%;
}

.desktop-customer-panel-table {
  overflow: hidden;
}

ul.desktop-customer-panel-table {
  border: 1px solid #c5c5c5;
  border-radius: var(--main-border-radius);
}
ul.desktop-customer-panel-table > li {
  border-top: 1px solid #c5c5c5;
}
ul.desktop-customer-panel-table > li > a > div > div {
  border-left: 1px solid #c5c5c5;
}
.rtl ul.desktop-customer-panel-table > li > a > div > div {
  border-left: none;
  border-right: 1px solid #c5c5c5;
}
ul.desktop-customer-panel-table > li > a > div > div:first-child {
  border-left: none;
  border-right: none;
}
ul.desktop-customer-panel-table > li > a {
  transition: all 0.1s linear;
}
ul.desktop-customer-panel-table > li > a:hover:not(ul.desktop-customer-panel-table > li > a.not-link:hover) {
  background-color: var(--foreground-gray-color);
}
ul.desktop-customer-panel-table > li:first-child {
  border-top: none;
}
.desktop-customer-panel-not-found {
  border: 1px solid #c5c5c5;
  border-radius: var(--main-border-radius);
}
.customer-panel-border {
  border: 1px solid #c5c5c5;
  border-radius: var(--main-border-radius);
}
.my-bookings-details-hotel-image-ctn {
  height: 190px;
  width: 190px;
}
.app-loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2100;
}
.desktop .my-bookings-detail-room-passengers {
  padding: 10px 0;
  width: 250px;
}
.desktop .my-bookings-detail-room-passengers .my-bookings-detail-room-passengers-text-ctn {
  width: 100px;
}
.custome-slider-wrapper .MuiSlider-mark {
  display: none;
}
.custome-slider-wrapper .MuiSlider-rail {
  height: 8px;
  border-radius: 8px !important;
  background-color: rgba(0, 0, 0, 0.12);
}
.custome-slider-wrapper .MuiSlider-track {
  height: 8px;
  border-radius: 8px !important;
  background-color: #008ceb;
}
.custome-slider-wrapper .MuiSlider-thumb {
  height: 18px;
  width: 18px;
  background-color: #e6e6e6;
  border: 1px solid #999999;
}
.custome-slider-wrapper .MuiSlider-valueLabel {
  left: calc(50% - 16px);
}
.custome-slider-wrapper .MuiSlider-valueLabel > span {
  background-color: #008ceb;
}
.rtl .custome-slider-wrapper .MuiSlider-valueLabel {
  left: auto;
  right: calc(50% - 16px);
}
.desktop .error-ctn {
  background-size: cover;
  background-position: bottom center;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.error-ctn.error-404 {
  background-image: url(./images/404-error-BG.png);
}
.rtl .error-ctn.error-404 {
  background-image: url(./images/404-error-BG-rtl.png);
}
.error-ctn.error-500 {
  background-image: url(./images/500-error-BG.png);
}
.rtl .error-ctn.error-500 {
  background-image: url(./images/500-error-BG-rtl.png);
}
.desktop .error-ctn .error-ctn-title {
  font-weight: 900;
  font-size: 40px;
  margin-bottom: 5px;
  margin-top: 10px;
  color: rgba(0, 0, 0, 0.75);
}
.desktop .error-ctn .error-ctn-description {
  font-size: 18px;
}
.desktop .error-ctn .error-ctn-go-to-home-button {
  margin-bottom: 20px;
  margin-top: 20px;
}
.desktop .error-ctn > div {
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.desktop .error-ctn > div > .error-image-ctn > img {
  width: 500px;
  height: auto;
}
.apply-ctn-in-drawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(50, 50, 50, 0.3);
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 10;
}
.MuiSkeleton-wave::after {
  animation-duration: 1s;
  background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.5), transparent);
}
.MuiSkeleton-root {
  background-color: rgba(0, 0, 0, 0.15);
}
#dragable {
  cursor: move;
  z-index: 10;
  position: absolute;
  text-align: center;
}
img.zoomable {
  transition: transform 0.3s ease-out;
}
img.unzoomed {
  cursor: zoom-in;
  transform: translate(0);
}
img.zoomed {
  left: 50%;
  top: 50%;
}
.desktop .room-option-cancellation-policy-ctn {
  border-top: 1px solid #a8a8a8;
  transition: all 0.2s;
}
.general-pages-header-ctn {
  border: 1px solid #c5c5c5;
}
.general-terms-and-conditions-ctn p,
.general-terms-and-conditions-ctn li,
.general-terms-and-conditions-ctn .general-description-content,
.general-privacy-policy-ctn p,
.general-privacy-policy-ctn li,
.general-privacy-policy-ctn .general-description-content {
  line-height: 25px;
}
.magazine-header-image-ctn {
  position: relative;
  background-image: url(./images/magazine-header-image.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.magazine-header-image-ctn > div {
  z-index: 1;
}
.desktop .magazine-header-image-ctn {
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.magazine-header-image-ctn::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(50, 50, 50, 0.5);
}
.magazine-search-ctn {
  position: relative;
}
.desktop .magazine-search-ctn {
  transform: translateY(50%);
}
.magazine-search-ctn input {
  width: 100%;
  border: 1px solid #c5c5c5;
  border-radius: var(--main-border-radius);
}
.desktop .magazine-search-ctn input {
  padding: 20px 20px;
  font-size: 1.2rem;
  font-weight: bold;
}
.magazine-search-ctn .magazine-search-btn-ctn {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
}
.rtl .magazine-search-ctn .magazine-search-btn-ctn {
  right: auto;
  left: 5px;
}
.magazine-search-ctn input::placeholder {
  color: #aaa;
}
.desktop .magazine-category-slider-item {
  background-color: var(--foreground-gray-color);
  border-radius: var(--main-border-radius);
  height: 80px;
  width: 200px;
}
.desktop .magazine-category-slider-item-active {
  background-color: #e0e0e0 !important;
  font-weight: bold;
}
.desktop .magazine-category-slider-item > a > span {
  padding: 5px;
}
.desktop .recent-articles-item {
  height: 235px;
  border-radius: var(--main-border-radius);
  background-color: transparent;
}
.desktop .recent-articles-title,
.desktop .recent-articles-summary {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}
.desktop .recent-articles-title {
  -webkit-line-clamp: 2;
}
.desktop .recent-articles-summary {
  -webkit-line-clamp: 5;
}
.editor-content-wrapper img {
  max-width: 100%;
}
.MuiPaginationItem-root {
  border-radius: var(--main-border-radius) !important;
}
.under-construction-ctn {
  height: 100vh;
}
.top-destinations-header-ctn {
  border-radius: var(--main-border-radius);
  position: relative;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.top-destinations-header-ctn::before {
  content: '';
  border-radius: var(--main-border-radius);
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #e9e9e9dd;
}
.top-destinations-header-ctn > div {
  z-index: 5;
}
.desktop .top-destination-item {
  border: 1px solid #c5c5c5;
  border-radius: var(--main-border-radius);
  padding: 10px;
  height: 100%;
  position: relative;
  transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -webkit-transition: all 0.3s ease-out;
  display: block;
  overflow: hidden;
}
.desktop .top-destination-item p {
  color: #000 !important;
}
.desktop .top-destination-item:before {
  content: '';
  position: absolute;
  top: 100%;
  left: -120px;
  right: -120px;
  bottom: -150%;
  background-color: var(--foreground-gray-color);
  opacity: 0;
  border-radius: 50% 50% 0 0;
  transition: all 0.6s ease-out;
  -o-transition: all 0.6s ease-out;
  -moz-transition: all 0.6s ease-out;
  -webkit-transition: all 0.6s ease-out;
  z-index: -1;
  transform: translateY(0);
}
.desktop .top-destination-item:hover::before {
  opacity: 1;
  transform: translateY(-100%);
}
.hotel-note-ctn {
  position: relative;
  padding-left: 20px;
}
.rtl .hotel-note-ctn {
  padding-left: 0;
  padding-right: 20px;
}
.hotel-note-ctn a {
  text-decoration: underline !important;
}
.hotel-note-ctn::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 4px;
  border-radius: var(--main-border-radius);
  background-color: var(--theme-orange);
}
.rtl .hotel-note-ctn::before {
  left: auto;
  right: 0;
}
.hotel-note-ctn .note-exclemation {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  color: #fff;
  background-color: var(--theme-orange);
  border-radius: var(--main-border-radius);
}
.rtl .hotel-note-ctn .note-exclemation {
  margin-left: 10px;
  margin-right: 0;
}
.hotel-note-ctn .note-text {
  color: var(--theme-orange);
  font-weight: bold;
  font-size: 1.1rem;
}
.hotel-note-ctn .note-content-ctn {
  color: var(--theme-orange);
}
.my-booking-notice-ctn {
  background-color: #cf3a1433;
}
.search-loading-wrapper {
  background-image: url(./images/search__form__bg.png);
  background-size: 80%;
  background-position: center;
  background-repeat: no-repeat;
}
.desktop-header-cmp {
  position: relative;
  display: flex;
}
.desktop-header-cmp > * {
  background-color: #fff;
  padding-right: 20px;
}
.rtl .desktop-header-cmp > * {
  padding-right: 0;
  padding-left: 20px;
}
.desktop-header-cmp::before {
  content: '';
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--foreground-gray-color-x);
}
.main-top-hotels-slider-ctn .swiper-button-next,
.main-top-hotels-slider-ctn .swiper-button-prev {
  height: 60px;
  width: 20px;
  background-color: var(--foreground-gray-color);
  box-shadow: 0 0 10px rgba(70, 70, 70, 0.4);
  color: #444;
  border-radius: var(--main-border-radius);
}
.main-top-hotels-slider-ctn .swiper-button-next,
.rtl .main-top-hotels-slider-ctn .swiper-button-prev {
  transform: translateY(-15px) translateX(15px);
}
.main-top-hotels-slider-ctn .swiper-button-prev,
.rtl .main-top-hotels-slider-ctn .swiper-button-next {
  transform: translateY(-15px) translateX(-15px);
}
.main-top-hotels-slider-ctn .swiper-button-prev::after,
.main-top-hotels-slider-ctn .swiper-button-next::after {
  font-size: 12px;
}
.desktop .full-navigation .swiper-button-next:not(.desktop .full-navigation .swiper-button-disabled):hover,
.desktop .full-navigation .swiper-button-prev:not(.desktop .full-navigation .swiper-button-disabled):hover {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
}
.desktop .full-navigation .swiper-button-next,
.desktop .full-navigation .swiper-button-prev {
  top: 0;
  bottom: 0;
  height: 100%;
  width: 50px;
  transition: all 0.2s;
}
.disabled-slider-navigation .swiper-button-prev,
.disabled-slider-navigation .swiper-button-next {
  display: none !important;
}
.react-transform-wrapper,
.react-transform-component {
  width: 100% !important;
  height: 100% !important;
}
.desktop .image-lightbox-ctn .react-transform-component {
  padding: 25px;
}
.hotel-offer-badge,
.hotel-type-badge {
  font-size: 10px;
  transform: translateY(-3px);
}
.hotel-type-badge {
  padding: 3px 6px;
  display: inline-block;
}
.hotel-offer-badge {
  font-size: 12px;
  background-color: var(--theme-green);
  color: white;
  padding: 4px 7px;
}

.register-slogan-title-ctn {
  position: relative;
  height: 70px;
}
.register-slogan-title-ctn::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 10px;
  background-color: var(--theme-secondary);
}
.rtl .register-slogan-title-ctn::before {
  left: auto;
  right: 0;
}
.map-error-ctn {
  background-color: #e8eaed;
  color: #3c4043;
  height: 100%;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.border-foreground-gray {
  border: 1px solid var(--foreground-gray-color-x);
}

.rtl #credential_picker_container {
  right: auto !important;
  left: 20px !important;
}
.footer-social-item {
  width: 50px;
  height: 50px;
  background-color: #fff;
  border-radius: var(--main-border-radius);
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer-social-item:hover {
  opacity: 0.7;
}
.main-application-description {
  text-align: justify;
  line-height: 1.5rem;
}
.main-faq-item-question-mark {
  background-color: #4b4e55;
  color: #fff;
  border-radius: var(--main-border-radius);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main-faq-item-question {
  color: #4b4e55;
  font-weight: bold;
  font-size: 1.1rem;
}
.main-tops-container-tabs-ctn {
  position: relative;
}
.main-tops-container-tabs-ctn .MuiTab-wrapper {
  font-size: 1.1rem;
}
.main-tops-container-tabs-ctn > div {
  z-index: 1;
}
.main-tops-container-tabs-ctn::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  z-index: 0;
  background-color: var(--foreground-gray-color-x);
}
.main-about-us-description {
  line-height: 1.5rem;
}
.desktop .main-faq-ctn ul {
  margin-left: -8px !important;
  margin-right: -8px !important;
}
.desktop .gray-main-faq-ctn ul {
  margin-left: -20px !important;
  margin-right: -20px !important;
}
.main-magazine-big-item .main-magazine-big-item-image-ctn {
  height: 300px;
}
.desktop .main-magazine-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  border-left: 1px solid #d5d5d5;
  padding-left: 15px;
  padding-right: 10px;
}
.desktop.rtl .main-magazine-sidebar {
  border-left: none;
  border-right: 1px solid #d5d5d5;
  padding-right: 15px;
  padding-left: 10px;
}
.desktop .main-application-ctn {
  max-width: 1000px;
}
.header-with-search-ctn {
  background-color: var(--theme);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.desktop .header-with-search-ctn {
  height: 630px;
}
.header-with-search-ctn .header-with-search-ctn-title {
  color: #fff;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
}
.desktop .header-with-search-ctn .header-with-search-ctn-title {
  min-width: 470px;
  padding: 0 5px 15px 5px;
  border-bottom: 2px solid #fff;
  margin-bottom: 100px;
}
.hover-underline:hover {
  text-decoration: underline;
}
.showMapButtonImageSrc {
  /*background-image: url(@hotelian/assets/images/show-on-map.jpg) !important;*/
  background-image: url(./images/show-on-map.jpg) !important;
}
.filter-searcher-ctn {
  overflow: hidden;
}
.badge-orange {
  background-color: var(--theme-orange);
}
.text-orange {
  color: var(--theme-orange);
}
.bg-orange {
  background-color: var(--theme-orange);
}
.reserve-leader-information-ctn {
  border: 1px solid var(--foreground-gray-color-x);
  border-radius: var(--main-border-radius);
  overflow: hidden;
}
.reserve-leader-information-ctn .reserve-leader-information-title {
  background-color: var(--light-primary-color);
  border-radius: var(--main-border-radius) var(--main-border-radius) 0 0;
}
.first-p-m-0 > p {
  margin: 0;
}
.search-box-skeleton-wrapper {
  background-color: var(--foreground-gray-color-transparent-1);
}
.reserve-no-gateway {
  z-index: 0 !important;
}
.cluster-marker-num {
  transform: translateY(-11px);
  font-weight: bolder;
}
.gm-style-iw-t {
  transform: translateY(-50px);
}
.search-box-item-spinner-wrapper {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 15;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hotel-descriptions {
  line-height: 1.5rem;
}

.hotel-descriptions .hotel-description-title {
  font-weight: 700;
  font-size: 1rem;
  margin-bottom: 0.4rem;
  margin-top: 1rem;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 36px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--theme);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

.rounded-circle {
  border-radius: 50%;
}

.width-fit-content {
  width: fit-content;
}

.hotel-offers-container {
  position: relative;
  top: 3px;
}

.hotel-facility-icon-container svg {
  max-width: 20px;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
#google-map {
  background-color: var(--foreground-gray-color);
}

.gateway-image-avatar img {
  object-fit: contain;
}
