/* Quicksand
@font-face {
  font-family: Quicksand;
  src: url(./fonts/quicksand/light.woff) format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: Quicksand;
  src: url(./fonts/quicksand/normal.woff) format("woff");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: Quicksand;
  src: url(./fonts/quicksand/bold.woff) format("woff");
  font-weight: 700;
  font-style: normal;
} */
/* Opensans */
@font-face {
  font-family: Opensans;
  src: url(./fonts/opensans/OpenSans-Light.woff) format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: Opensans;
  src: url(./fonts/opensans/OpenSans-Regular.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Opensans;
  src: url(./fonts/opensans/OpenSans-Bold.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}

/* Iran sans */
@font-face {
  font-family: Iransans;
  src: url(./fonts/iransans/medium.woff) format('woff');
  font-weight: 300;
  font-style: medium;
}

@font-face {
  font-family: Iransans;
  src: url(./fonts/iransans/normal.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: Iransans;
  src: url(./fonts/iransans/bold.woff) format('woff');
  font-weight: 700;
  font-style: bold;
}

/*Noto  Sans Arabic*/
@font-face {
  font-family: 'Noto Sans';
  src: url(./fonts/notosans/NotoSansArabic-Light.woff) format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(./fonts/notosans/NotoSansArabic-Regular.woff) format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Noto Sans';
  src: url(./fonts/notosans/NotoSansArabic-Bold.woff) format('woff');
  font-weight: 700;
}

:root {
  --theme-secondary: #cf142b;
  --theme: #00247d;
  --theme-green: #7fbb01;
  --theme-orange: #f70;
  --theme-orange-darker: #df7f00;
  --theme-green-light: #b2db58;
  --theme-transparent: #00247dee;
  --theme-transparent-x: #00247ddd;
  --theme-transparent-xx: #00247daa;
  --theme-light-xx: #00247d55;
  --gray-color: #9b9b9b;
  --filled-input-background-color: #f8f8f8;
  --dark-gray-color: #4b4d55;
  --b2b-main-bg-color: #f8f8f8;
  --b2b-light-border-color: #e6e6e6;
  --b2b-medium-border-color: #cccccc;
  --gray-color-light: #eee;
  --gray-color-light-x: #ebebeb;
  --teal-color: #359bba;
  --bootstrap-border-primary: #007bff;
  --light-primary-color: #d4daea;
  --foreground-gray-color: #f5f5f5;
  --foreground-gray-color-transparent-1: #f5f5f5aa;
  --foreground-gray-color-darker: #f1f1f1;
  --foreground-gray-color-x: #dddddd;
  --main-font-family: Opensans;
  --main-border-radius: 5px;
  --mobile-header-height: 50px;
  --mobile-drawer-width: 300px;
  --mobile-drawer-width-n: -300px;
  --mobile-max-width: 768px;
  --desktop-max-width: 1300px;
}

:root body.fa_lang {
  --main-font-family: Iransans;
}

:root body.ar_lang {
  --main-font-family: 'Noto Sans', BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

.en-font {
  font-family: Opensans BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
}

@media (max-width: 1440px) {
  :root {
    --desktop-max-width: 1160px;
  }
}
